<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .fehlerseite-all {
    .nr {
      padding:30px;
      font-size:1000%;
      color:#ccc;
      font-family: 'Helvetica Neue LT W05 96 Blk It';
      letter-spacing: 1px;
    }
    .link {
      color:$violet;
      font-family: 'Helvetica Neue LT W05 65 Medium';

    }
    .btn {
      background:$sand;
      border-radius: 0;
      display:block;
      padding:20px;
      font-size:110%;
      margin:0 20px;
    }
    
  
   
    
  }
}
</style>

<template>
  <div class="fehlerseite-all">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center padding-tb-s">
          <div class="nr">500</div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center align-self-center">
        <div class="col-lg-6 col-10 align-self-center text-center">
        <p>Hier fehlen selbst uns die Worte.</p>
        <p>Aber wir wollen uns ständig weiterentwickeln.<br>
        <router-link :to="{ name: 'talentepool' }" class="link">Sie auch? Dann ab in unseren Talentepool!</router-link></p>

        </div>
      </div>
      <div class="row justify-content-center align-self-center margin-t-m">
        <div class="col-lg-4 col-10 align-self-center text-center">
          <p><router-link :to="{ name: 'Home' }" class="btn">Zur Startseite</router-link></p>

        </div>
        <div class="col-lg-4 col-10 align-self-center text-center">
          <p><router-link :to="{ name: 'suche' }" class="btn">Suche</router-link></p>
        </div>
        <div class="col-lg-4 col-10 align-self-center text-center">
          <p><router-link :to="{ name: 'kontakt' }" class="btn">Kontakt</router-link></p>
        </div>
      </div>
    </div>
    
  
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Footer from '../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Styria Media Group. One spirit – unlimited ideas.',
      keywords: ['media company', 'styria' , 'medien' , 'graz' , 'styria media group'],
      description: 'Die STYRIA ist einer der führenden Medienkonzerne in Österreich, Kroatien und Slowenien. Orientierung geben, Vertrauen schaffen, Gemeinschaft fördern.',
      image: require('@/assets/img/og/startseite.png'),
      
    },
    en: {
      title: 'Styria Media Group. One spirit – unlimited ideas.',
      keywords: ['medienkonzern', 'styria' , 'medien' , 'graz' , 'styria media group'],
      description: 'STYRIA is one of the leading media groups in Austria, Croatia and Slovenia. Providing orientation, creating trust, promoting community.',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
  components: {

    Footer,

    
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
